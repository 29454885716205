import { api, helper } from ":mods";
import { ENDPOINTS } from "../const";
import { materialLessonDetails } from "../mocks";
import { ASubmoduleMaterial, MaterialDetails, MaterialList } from "../models";

export async function getMaterialList(id: number | string) {
  return api.getAuth(ENDPOINTS.material_list_with_submodule_id + id).then((res) => {
    // console.log("calling async :: ", id, res);
    return res.data as MaterialList;
  });
}
export async function getMaterialDetails(id: number | string) {
  // if (helper.env.isDev) return materialLessonDetails;
  return api.getAuth<MaterialDetails>(ENDPOINTS.material_details_with_material_id_student + id).then((res) => {
    // console.log("material details :: ", res.data);
    return res.data as MaterialDetails;
  });
}

export async function createMaterial(id: number | string, data: ASubmoduleMaterial): Promise<any> {
  return api
    .postAuth(ENDPOINTS.material_list_with_submodule_id + id, {
      ...data,
      submodule: id,
    })
    .then((res) => {
      return Promise.resolve(res.data.data);
    });
}
export async function updateMaterial(id: number | string, data: ASubmoduleMaterial): Promise<any> {
  return api.putAuth(ENDPOINTS.material_details_with_material_id + id, data).then((res) => res.data);
}
export async function deleteMaterial(ids: number[]): Promise<{ id: number; index: number; error?: string }[]> {
  return new Promise(async (res, rej) => {
    const result = [] as { id: number; index: number; error?: string }[];
    for (const id of ids) {
      const index = ids.indexOf(id);
      // result.push({ id, index });
      await api
        .delAuth(ENDPOINTS.material_details_with_material_id + id)
        .then((res) => {
          result.push({ id, index });
        })
        .catch((err) => {
          result.push({ id, index, error: err.response.data.error });
        });
    }
    res(result);
  });
}
